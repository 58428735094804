import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PhoneInputComponent } from "../../../../@shared/components/phone-input/phone-input.component";
import { DropdownModule } from 'primeng/dropdown';
import { AuthenticationService } from '../../../../@core/services/authentication.service';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router } from '@angular/router';
import slug from 'slug';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { catchError } from "rxjs";
import { IUser } from '../../../../@core/models/model';

@Component({
  selector: 'app-onboard-org',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule, ButtonModule, CheckboxModule, RadioButtonModule, CommonModule, PhoneInputComponent, DropdownModule, PasswordModule, ConfirmPopupModule],
  templateUrl: './onboard-org.component.html',
  styleUrl: './onboard-org.component.scss',
  providers: [ConfirmationService]
})
export class OnboardOrgComponent implements OnInit {
  profileForm!: FormGroup;
  organizationForm!: FormGroup;
  teamForm!: FormGroup;
  currentStep = 1;
  totalSteps = 4;
  role: string = ''
  profileImage: string = '';
  email: string = ''
  sendOverEmail = false
  currentUser!: IUser | null | undefined
  value!: string;
  passwordMatched: boolean = true;
  workspacePrefix = 'inncircles.ai/';
  workspaceInputValue = '';
  name: string = ''
  roleError: boolean = false;
  expertiseError: boolean = false;
  selectedGender: string = '';
  submitInProcess: boolean = false

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService
  ) {
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
    });
  }

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email') ?? '';
    if (!this.email) {
      this.router.navigate(['/signup']);
      return
    }
    this.initializeForms();
  }

  roles = ["Project Manager", "Site Engineer", "Quantity Surveyor", "Contract Administrator", "Safety Officer", "Document Controller", "Estimator", "Bid Manager"];
  domainExpertise = ["Civil Works", "Structural Works", "Project Management", "Construction Law", "Quantity Surveying", "Environmental Engineering", "Risk Management", "Construction Safety"];
  members = [
    { email: '', role: '' },
    { email: '', role: '' }
  ]
  selectedRole: string | null = null;
  selectedExpertise: string | null = null;
  noOfEmployees = ['1-10', '11-50', '51-100', '101-500', '501-1000', '1000+'];
  userRoles = ['Admin', 'Member'];
  imageFile: any
  selectedNoOfEmployees: string = ''

  // Calculates the percentage based on the current step
  get progress(): string {
    return `${(this.currentStep / this.totalSteps) * 100}%`;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event: Event) {
    $event.returnValue = true
  }

  // Move to the next step
  nextStep() {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    }
  }

  // Move to the previous step
  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  onUrlInput(event: any) {
    const inputValue = event.target.value;

    //TODO:  check for already existing workspace  
    this.authService.checkOrganization(inputValue.split('/')[1]).subscribe(res => {
      if (res.success) {
        this.organizationForm.controls['workspaceUrl'].setErrors({ 'alreadyExists': true });
      }
    })
    if (inputValue.startsWith(this.workspacePrefix)) {
      this.workspaceInputValue = inputValue.replace(this.workspacePrefix, '');
      this.workspaceInputValue = this.workspaceInputValue.replace(/[^a-zA-Z0-9-]/g, '-');
      this.workspaceInputValue = slug(this.workspaceInputValue, '-').toLocaleLowerCase();
    } else {
      event.target.value = this.workspacePrefix + this.workspaceInputValue;
      this.organizationForm.get('workspaceUrl')?.setValue(this.workspacePrefix + this.workspaceInputValue);
    }
  }

  addMember() {
    this.members.push({ email: '', role: '' })
  }

  removeMember(event: Event, index: number) {
    event.preventDefault()
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'bg-white rounded-xl border border-green-500 text-green-500 hover:bg-green-100 hover:text-green-500 px-4 py-1',
      rejectButtonStyleClass: 'bg-white rounded-xl border border-red-500 text-red-500 hover:bg-red-100 hover:text-red-500 px-4 py-1 mx-3',
      accept: () => {
        this.members.splice(index, 1)
      },
      reject: () => {

      }
    });

  }

  selectRole(role: string): void {
    this.selectedRole = role;
  }

  selectExpertise(expertise: string): void {
    this.selectedExpertise = expertise;
  }

  initializeForms(): void {
    // Step 2: Profile Setup Form Group
    this.profileForm = this.fb.group({
      email: [this.email, [Validators.required, Validators.email]],
      username: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      profilePicture: ['https://cdn.jsdelivr.net/gh/alohe/avatars/png/vibrent_6.png'],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, { validators: [this.passwordMatchValidator] });

    // Step 3: Organization Setup Form Group
    this.organizationForm = this.fb.group({
      companyName: ['', Validators.required],
      workspaceUrl: ['inncircles.ai/'],
      companySize: ['', Validators.required],
      contact: [null, Validators.required],
      address: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.email]]
    });
  }

  onCompanyNameChange(event: any) {
    const companyName = event.target.value;
    const workspaceUrl = this.organizationForm.get('workspaceUrl')?.value;
    if (workspaceUrl === 'inncircles.ai/') {
      this.workspaceInputValue = slug(companyName, '-').toLocaleLowerCase();
    }
    this.organizationForm.get('workspaceUrl')?.setValue(this.workspacePrefix + companyName);
  }

  avatarValidator() {
    const group = this.profileForm;
    const gender = group.get('gender')?.value;
    const currentPicture = group.get('profilePicture')?.value;

    if (!gender || currentPicture && !currentPicture.startsWith('http')) {
      return true; // Skip if a custom picture is already set
    }

    group.get('profilePicture')?.setValue(
      gender === 'MALE'
        ? 'https://cdn.jsdelivr.net/gh/alohe/avatars/png/vibrent_6.png'
        : 'https://cdn.jsdelivr.net/gh/alohe/avatars/png/vibrent_1.png'
    );
    return true;
  }

  passwordMatchValidator(group: FormGroup) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  onSubmitProfile(): void {
    if (!this.profileForm.valid) {
      this.profileForm.markAllAsTouched();
      return
    }
    this.name = this.profileForm.get('firstName')?.value
    this.nextStep();
  }

  onDomainSubmit(): void {
    this.roleError = this.selectedRole === null;
    this.expertiseError = this.selectedExpertise === null;
    if (this.roleError || this.expertiseError) {
      return;
    }
    this.nextStep();
  }

  onSubmitOrganization(): void {
    if (!this.organizationForm.valid) {
      this.organizationForm.markAllAsTouched();
      return
    }
    this.nextStep();
  }

  onSubmitTeam(): void {
    if (this.members.some(member => member.email === this.profileForm.get('email')?.value)) {
      this.toastr.error('You cant add yourself as a team member');
      return
    }

    if (!this.members.every(member => member.email && member.role)) {
      this.toastr.error('Please fill team members details');
      return
    }
    this.onSubmit();
  }

  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      this.imageFile = file
      this.profileForm.patchValue({
        profilePicture: URL.createObjectURL(file),
      });
    }
    input.value = '';
  }

  onSubmit(skip = false): void {
    if (this.submitInProcess) return;
    this.submitInProcess = true;
    if (this.profileForm.valid && this.organizationForm.valid) {
      const formData = new FormData();
      formData.append('firstName', this.profileForm.get('firstName')?.value);
      formData.append('lastName', this.profileForm.get('lastName')?.value);
      formData.append('fullName', this.profileForm.get('firstName')?.value + ' ' + this.profileForm.get('lastName')?.value);
      formData.append('email', this.profileForm.get('email')?.value);
      formData.append('username', this.profileForm.get('username')?.value);
      formData.append('gender', this.profileForm.get('gender')?.value);
      const file = this.profileForm.get('profilePicture')?.value;
      if (file) {
        formData.append('profilePicture', this.imageFile);
      }
      formData.append('password', this.profileForm.get('password')?.value);

      formData.append('role', this.selectedRole ?? '');
      formData.append('domain', this.selectedExpertise ?? '');

      formData.append('organization', JSON.stringify(this.organizationForm.value));
      if (this.currentUser) {
        formData.append('userId', this.currentUser.id);
      }
      if (!skip) {
        this.members.forEach(member => {
          member.role = member.role.toUpperCase()
        })
        formData.append('members', JSON.stringify(this.members));
      }

      this.authService.signUp(formData).pipe(catchError((error) => {
        this.submitInProcess = false
        return error;
      })).subscribe({
        next: (userLoggedIn) => {
          if (userLoggedIn) {
            this.submitInProcess = false
            this.toastr.success('Success', 'Account created successfully');
            this.router.navigate(['/home']);
          }
        },
        error: (err) => {
          this.submitInProcess = false
          this.toastr.error('Error', ' Something went wrong! Please try again');
        }
      })


    }
  }

  removePicture(): void {
    this.profileImage = '';

  }

  skip(): void {
    this.onSubmit(true);
  }

  onContinue() {
    if (this.organizationForm.invalid) {
    }
    else {
      this.organizationForm.markAllAsTouched
    }
  }

  onPhoneNumberChange(phoneNumber: { countryCode: string, contactNumber: string }) { }
}
