import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { environment } from '../../../environments/environment.dev';
import { BehaviorSubject, Observable } from "rxjs";
import { BaseService } from "../../@core/services/base.service";
import { SIGNATORY_TYPE, CONTRACT_TYPE, IContract, IContractApprovalConf, IContractSignatoryConf } from "../models/contract-management.model";
import { IAttachment, IUser } from "../../@core/models/model";
import { IDocumentType } from "../models/settings.model";
import { IFile } from "../components/settings/repository/repository.types";

interface IContractFilters {
    searchValue?: string,
    date?: Date
}

interface InsightDetails {
    inProgressJob: {
        username: string
    } | null,
    latestInsights: {
        username: string,
        updatedAt: Date
    } | null
}

@Injectable({ providedIn: 'root' })
export class ContractService extends BaseService {
    connectedUsers$ = new BehaviorSubject<IUser[]>([]);
    aiGeneratedContent = new BehaviorSubject<string>('');

    constructor(
        protected override http: HttpClient,
        protected ngZone: NgZone
    ) {
        super(http, ngZone);
    }


    apiUrl = environment.apiUrl + '/contract-management'

    getSharedContracts() {
        return this.get<IContract[]>(this.apiUrl + '/shared-contracts')
    }

    getContractURL(id: string) {
        return this.get<{ url: string, key: string, attachment: IAttachment }>(this.apiUrl + '/contract-url/' + id);
    }

    getContracts(filters: { type: CONTRACT_TYPE, skip: number, limit: number, name: string, excludeDocs?: string[] }) {
        return this.post<{ contracts: IContract[], total: number }>(this.apiUrl + '/contracts', filters)
    }

    getAiGeneratedContent() {
        return this.aiGeneratedContent.asObservable();
    }

    setAiGeneratedContent(content: string) {
        return this.aiGeneratedContent.next(content);
    }

    getContractRiskScore(contractId: string) {
        return this.get<number>(this.apiUrl + `/risk/${contractId}`)
    }

    getLatestInsightsDetails(contractId: string) {
        return this.get<InsightDetails>(this.apiUrl + `/latest-insight-details/${contractId}`)
    }

    shareContract(contractId: string, users: { user: IUser, role: string }[]) {
        return this.post<IContract>(this.apiUrl + '/share-contract/' + contractId, { data: users })
    }

    fetchContract(contractId: string) {
        return this.get<IContract>(this.apiUrl + '/fetch-contract/' + contractId)
    }

    generateContractWithAi(prompt: string, docTypeId?: IDocumentType['_id'], linkedDocId?: string, template?: IFile['_id']) {
        return this.post<string>(this.apiUrl + '/generate-with-ai', { prompt, ...(docTypeId ? { docTypeId } : {}), ...(linkedDocId ? { linkedDocId } : {}), template })
    }

    updateSignatories(contractId: string, signatories: { email: string, name: string }[]) {
        return this.post<IContract>(this.apiUrl + '/add-signatories/' + contractId, { signatories })
    }


    fetchContractSignatories(contractId: string) {
        return this.get<IContractSignatoryConf>(environment.apiUrl + '/signatory/fetch-contract-signatories/' + contractId)
    }

    deleteDocRole(contractId: string, user: IUser['_id']) {
        return this.delete<string>(this.apiUrl + '/remove-doc-role/' + contractId + '/' + user)
    }
    changeDocRole(contractId: string, user: IUser['_id'], role: string) {
        return this.post<IContract>(this.apiUrl + '/change-user-role/' + contractId + '/' + user, { role })
    }

    createContract(data: FormData) {
        return this.post<IContract>(this.apiUrl + '/create-contract', data)
    }

    updateContract(contractId: string, contract: IContract | Partial<IContract>): Observable<any> {
        return this.http.put(`${this.apiUrl}/edit-contract/${contractId}`, contract);
    }

    uploadContract(formData: FormData) {
        return this.post<IContract>(this.apiUrl + '/upload-contract', formData)
    }

    startSignatoryFlow(contractId: string, signatoryType: SIGNATORY_TYPE) {
        return this.post<IContract>(this.apiUrl + '/start-signatory-flow', {
            contractId,
            signatoryType
        })
    }

    resetSignatoryFlow(contractSignatoryConfId: string) {
        return this.post<IContract>(this.apiUrl + '/reset-signatory-flow/' + contractSignatoryConfId)
    }

    setDefaultSignatory(signatory: SIGNATORY_TYPE) {
        return this.post<IContract>(this.apiUrl + '/set-default-signatory', { signatory })
    }

    getDefaultSignatory() {
        return this.get<{
            defaultSignatory: SIGNATORY_TYPE,
        }>(environment.apiUrl + '/signatory/get-default-signatory')
    }

    downloadDocument(attachmentId: string) {
        return this.get<{ data: Uint8Array }>(this.apiUrl + '/download-signed-document/', { attachmentId })
    }


    downloadContract(contractId: string, type: string) {
        return this.post<{ data: Uint8Array }>(this.apiUrl + '/download-contract', { id: contractId, type: type })
    }

    fetchLastModifiedContract() {
        return this.get<any>(this.apiUrl + '/last-modified-contract')
    }

    deleteContract(contractId: string) {
        return this.delete<IContract>(this.apiUrl + '/delete-contract/' + contractId)
    }

    getDocumentsByType(type: string, filters: { search: string, skip: number, limit: number, excludeDocs: string[] }) {
        return this.post<{ contracts: IContract[], total: number }>(this.apiUrl + '/documents-by-type', { type, filters })
    }

    getCompareDocuments(filters: { document: string, compareDocuments: { _id: string, docModel: string, name: string }[], compareType: string }) {
        return this.post<{
            document: IContract & { summary: string },
            compareDocuments: (IContract | IFile & { summary: string })[]
        }>(this.apiUrl + '/compare-documents', filters)
    }
    getRelatedTemplates(prompt: string) {
        return this.post<IFile[]>(this.apiUrl + '/get-prompt-related-templates', { prompt })
    }
}
