<div class="aspect-auto flex h-screen w-screen">
    <div class="left-section">
        <div class="left-section-content">
            <div class="header text-4xl">
                Welcome Back,
                <br>
                Sign-in to your account!
            </div>
            <div class="my-2 text-base mt-4">Don't have one?
                <span class="theme-text font-medium cursor-pointer" (click)="redirectToSignup()">Create an
                    account</span>
            </div>

            <div class="my-3">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label class="login-label" for="username ">Email</label>
                            <input id="username" class="input" type="email" pInputText formControlName="username"
                                placeholder="name@example.com">
                        </div>

                        <div class="p-field mt-4">
                            <label class="login-label" for="password">Password</label>
                            <p-password id="password" class="input " formControlName="password" [feedback]="false"
                                [toggleMask]="true"></p-password>

                            <!-- <small class="password-hint "><span [class]="'pi pi-info-circle'"
                                    class="p-info-icon"></span>
                                <span class="ml-1">Password should contain at least one special
                                    character</span>
                            </small> -->
                        </div>

                        <div class="mt-10">
                            <p-button styleClass="bg-arena-orange p-3 rounded-xl text-white font-semibold" type="submit"
                                label="Login" [disabled]="!loginForm.valid"></p-button>
                        </div>
                        <div class="mt-6 text-center font-medium  text-lg forgot-password"
                            [routerLink]="'/forgot-password'">
                            <small class="cursor-pointer  hover:text-orange-500">Forgot password?</small>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class=" right-section">

        <!-- <div class="login-right-section">
            <div class="header text-center">
                <span class="theme-text">
                    A completely new way to
                    <br>
                    handle your legal documents
                </span>
            </div>
        </div> -->


        <img class="login-image" src="/images/login-page.png" alt="login-image">
    </div>
</div>