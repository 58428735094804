import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../../@core/services/base.service";
import { environment } from "../../../environments/environment.dev";

export interface IGetContractTagMappings {
    tagTypeId: string,
    tagTypeName: string,
    dataType: string,
    tags?: {
        tagId: string,
        aiGenerated: boolean,
        tagName: string
        dataType: string
    }[],
    mappingsInSync: boolean
}

export interface IContractSingleTag {
    contract: string,
    tagType: string,
    tag: string
}

@Injectable({ providedIn: 'root' })
export class ContractTagService extends BaseService {

    constructor(protected override http: HttpClient) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/contract-tag';

    getContractTagMappings(contractId: string) {
        return this.get<{ mappings: IGetContractTagMappings[], documentInSync: boolean }>(this.apiUrl + `/${contractId}`)
    }

    getTagsBasedOnSearchValue(data: { contract: string, tagType: string, searchValue: string }) {
        if (data.searchValue == undefined) { data.searchValue = '' }
        return this.get<{ _id: string, name: string }[]>(this.apiUrl + '/tags/search', data)
    }

    addContractTagMapping(data: IContractSingleTag) {
        return this.post(this.apiUrl + '/add', data)
    }
    addAItagToContractTagMapping(data: IContractSingleTag & { tagName: string }) {
        return this.post(this.apiUrl + '/add-ai-tag', data)
    }
    removeContractTagMapping(data: IContractSingleTag) {
        return this.post(this.apiUrl + '/remove', data)
    }

}