@if(showHistory){
<div class="relative">
    <div class="flex gap-2 items-center justify-end p-2 h-10">
        <div pRipple class="justify-end" (click)="historyPanel.toggle($event)">
            <i class="pi pi-history p-2 rounded-full text-white bg-arena-orange-800 cursor-pointer"></i>
        </div>
    </div>
</div>
}

<div class="inn-flex-container flex flex-col justify-between overflow-x-hidden">
    <div class="chat-section inn-flex-scroll overflow-x-hidden" #scrollBottom>
        @if(showSuggestions){
        <div class="prompt-container">
            <!-- Bot Icon -->
            <div class="bot-icon">
                <img src="icons/ai_chat.svg" alt="Bot Icon" />
            </div>

            <!-- Title -->
            <p class="title"> Get started with these prompts</p>

            <!-- Prompt Buttons -->
            <div
                class="flex justify-start items-center overflow-x-auto whitespace-nowrap mt-4 w-full scroll-smooth snap-x snap-mandatory no-scrollbar">
                @for (question of promptQuestions; track $index) {
                <div class="cursor-pointer border border-gray-300 rounded-full text-xs text-center bg-white z-10 font-semibold justify-center p-2 items-center mx-1 snap-start"
                    (click)="prompt = question; sendPrompt()">
                    {{ question }}
                </div>
                }
            </div>

            <!-- SVGS -->
            <div class="vector-left">
                <img src="/images/yellow_vector.svg" alt="" />
            </div>
            <div class="vector-right">
                <img src="/images/blue_vector.svg" alt="" />
            </div>
        </div>
        }
        @if(!showSuggestions){
        <div class="mt-4">
            @for (conversation of conversations; track $index) {
            <app-ai-chat-bubble class="w-full" [Chat]="conversation"></app-ai-chat-bubble>
            }
        </div>
        }

    </div>
    <div class="justify-end bg-transparent overflow-x-hidden">
        @if(showLimitWarning){
        <div
            class="bg-orange-50  border-b  border-t border-gray-200 text-sm text-arena-orange-800 px-3 py-2 flex justify-between items-center ">
            <span class="text-xs"><span class="px-[0.1rem] rounded-full bg-arena-orange-800  mr-1"></span>Tip: Long
                chats may
                cause you to
                reach your usage limits faster.</span>
            <a href="#" class="text-arena-orange-800  text-xs font-semibold" (click)="startNewConversation()">New
                chat</a>
            <i class="pi pi-times-circle cursor-pointer" (click)="showLimitWarning=false"></i>
        </div>
        }
        <div class="flex flex-col fixed-actions no-scroll gap-2">
            <div class="flex justify-between items-center prompt border-t-gray-200">
                <span class="svg-icon ml-2 stars" [innerHTML]="icons.AI_CHAT_STARS | sanitizeHtml"></span>
                <input type="text" placeholder="Type Something ..." [maxlength]="maxPromptLength" [(ngModel)]="prompt"
                    [disabled]="disabled" (input)="checkPromptLength()" (keydown.enter)="sendPrompt()"
                    class="w-full promptInput">
                @if (!loading) {
                <span [ngClass]="{'disabled-svg': disabled || promptLength<1 , 'send' : !disabled || promptLength>1}"
                    class="svg-icon mr-2" [innerHTML]="icons.CHAT_BOT_SEND | sanitizeHtml"
                    (click)="sendPrompt()"></span>
                }
                @if (loading) {
                <span class="loader mr-2"></span>
                }
            </div>
            <div class="flex justify-between gap-1 ml-3 mr-3 info">
                <div class="w-full text-sm">
                    This is an experimental generative AI Chatbot
                </div>
                <div>
                    @if (promptLength >= maxPromptLength) {
                    <div class="text-sm text-red-500">
                        {{promptLength}}/{{maxPromptLength}} (Maximum reached)
                    </div>
                    }
                    @if (promptLength < maxPromptLength) { <div>
                        {{promptLength}}/{{maxPromptLength}}
                </div>
                }

            </div>
        </div>
    </div>
</div>

<p-overlayPanel #historyPanel appendTo="body" styleClass="rounded-xl border"
    [style]="{'width':'24rem' , 'max-height' : '30rem'}">

    <div class="flex flex-col gap-2 p-2 overflow-y-auto" style="max-height: 25rem;">
        <div class="my-3 items-center">
            <p-inputGroup class="flex items-center border rounded-xl px-2 bg-white" styleClass="items-center">
                <p-inputGroupAddon class="cursor-pointer">
                    <i class="pi pi-search text-arena-orange text-lg"></i>
                </p-inputGroupAddon>
                <input pInputText type="text" placeholder="Search a conversation" (input)="searchConversations($event)"
                    class="flex-1 outline-none border-none rounded-xl p-2" />
            </p-inputGroup>
        </div>
        @for (history of conversationsHistory; track $index) {
        @if (history.length != 0) {
        <span class="text-xs">{{$index == 0 ? 'Today' : $index == 1 ? 'Last 7 days' : 'Last 30 Days'}}</span>
        @for (item of history; track $index) {
        <div class="rounded-lg hover:bg-gray-200 p-2 cursor-pointer flex  justify-between items-center"
            [ngClass]="{'bg-orange-200': selectedConversation == item._id}"
            (click)="enableRename && enableRename._id == item._id ? null : getMessages(item._id ,historyPanel , $event)">
            @if(enableRename && enableRename._id == item._id){
            <span #editableContent scrollReset [attr.contenteditable]="true"
                (click)="enableRename = item; $event.stopPropagation();"
                class="text-sm mx-3 max-w-[400px] whitespace-nowrap overflow-x-auto inline-block no-scrollbar"
                (keydown)="$event.key === 'Enter' ? blurSpan($event) : null"
                (blur)="updateConversationName($event,item)">
                {{item.name}}
            </span>
            }
            @else{
            <div class="flex flex-col gap-1">
                <span>{{item.name | truncate:30}}</span>
                <span class="text-xs text-gray-500">{{item.createdAt |date }}</span>
            </div>

            }
            <div>
                <i class="pi pi-ellipsis-v" (click)="historyOptions.toggle($event);$event.stopPropagation()"></i>
                <p-overlayPanel #historyOptions styleClass="rounded-xl border" appendTo="body"
                    [style]="{'width':'10rem' , 'max-height' : '30rem'}">
                    <div class="flex flex-col gap-1 rounded-lg justify-center">
                        <div class="hover:bg-gray-200 p-1 cursor-pointer flex gap-2 items-center rounded-lg"
                            (click)="renameChatHistory(item);historyOptions.toggle($event)">
                            <i class="pi pi-pencil"></i>
                            <span class="hover:bg-gray-200 rounded-lg">Rename</span>
                        </div>
                        <div class="hover:bg-gray-200 p-1 cursor-pointer gap-2 flex items-center rounded-lg"
                            (click)="deleteConversation(item);historyOptions.toggle($event)">
                            <i class="pi pi-trash text-red-500"></i>
                            <span class=" hover:bg-gray-200 rounded-lg text-red-500">Delete</span>
                        </div>
                    </div>
                </p-overlayPanel>
            </div>

        </div>
        }
        }
        }
        @if(!conversationsHistory || conversationsHistory.length == 0 || conversationsHistory[0].length == 0 &&
        conversationsHistory[1].length ==
        0 && conversationsHistory[2].length == 0 ){
        <div class="text-center text-sm">No Conversations</div>
        }
    </div>

    <div class="p-2 items-center">
        <p-button label="New Chat" styleClass=" w-full bg-arena-orange p-2 rounded-xl text-white font-semibold"
            type="submit" (click)="startNewConversation();historyPanel.toggle($event)"></p-button>
    </div>
</p-overlayPanel>