import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  OnInit
} from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { ToastService } from '../../../@core/services/toast.service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-file-drop',
  standalone: true,
  imports: [CommonModule, ToastModule, ButtonModule],
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {
  @Input() title: string = '';
  @Input() type: string = 'file';
  @Input() showDesc: boolean = true;
  @Input() fileTypes: string[] = [];
  @Input() displayText: boolean = true;
  @Input() maxFileSize: number = 1 * 1024 * 1024

  @Output() uploadData = new EventEmitter<File | File[]>();
  @Output() isFileadded = new EventEmitter<boolean>();

  dragAreaClass: string = '';
  fileName: string = '';
  isFileAdded: boolean = false;
  message: string = '';
  public validFilesLength: number = 0;

  constructor(
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.dragAreaClass = 'dragarea';
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event: DragEvent) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.files) {
      const files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files: FileList = input.files;
      this.saveFiles(files);
      input.value = '';
    }
  }

  saveFiles(files: FileList) {
    if (!files || files.length === 0) return;

    // Handle uploads
    const validFiles: File[] = [];
    const invalidFiles: string[] = [];

    Array.from(files).forEach((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';

      if (
        this.fileTypes.length > 0 &&
        !this.fileTypes.includes('.' + fileExtension)
      ) {
        invalidFiles.push(file.name);
      } else if (file.size > this.maxFileSize) {
        invalidFiles.push(file.name);
      } else {
        validFiles.push(file);
      }
    });

    if (validFiles.length > 0) {
      this.validFilesLength = validFiles.length;
      this.message = '';
      this.isFileAdded = true;
      this.fileName = validFiles.length > 1 ? `${validFiles.length} files are uploaded` : '1 file uploaded';
      this.uploadData.emit(validFiles); // Emit the array of files
      this.isFileadded.emit(true);
    } else {
      this.toast.error('No valid files to upload');
    }

    if (invalidFiles.length > 0) {
      this.toast.error(
        `The following files are invalid or exceeds size limit: ${invalidFiles.join(
          ', '
        )}`
      );
      return;
    }
  }

  removeUploadedFile() {
    this.fileName = '';
    this.isFileAdded = false;
    this.uploadData.emit([]);
    this.isFileadded.emit(false);
    this.message = '';
    this.toast.success('File(s) removed successfully');
  }
}
