<p-dialog [(visible)]="visible" [header]="'Header'" [style]="{width: '43vw'}" [draggable]="false" [modal]="true"
    (onHide)="cancel()" (clickOutside)="cancel()">
    <ng-template pTemplate="header">
        <div class="flex flex-row items-center gap-1 theme-text">
            <span class="mr-2 material-icons-outlined">smart_toy</span>
            <div class="text-lg font-bold">Ask AI Assistant</div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        @if(templateSuggestionLoading && !aiAssistant.loading){
        <div class="flex flex-col gap-2">
            <div class="flex flex-col font-semibold items-center justify-center p-2">
                <img class="h-[12rem]  w-[10rem] animate-pulse " src="images/insights-image.png" alt="image">
                <span class="theme-text animate-pulse">{{aiAssistant.stage}}</span>
            </div>
            <div class="grid grid-cols-4 gap-2 p-2 items-center overflow-y-auto">
                <div (click)="fromScratch = !fromScratch ; selectedTemplate =null"
                    [ngClass]="{'border-orange-500': fromScratch , 'bg-orange-50' : fromScratch}" class="flex text-sm cursor-pointer bg-white rounded-lg p-2 items-center gap-2 border w-100 h-full
                    min-h-[2rem] hover:bg-orange-100">
                    <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top" class=" h-5 w-5"
                        alt="Star icon">
                    <span class="text-xs">Generate from Scratch</span>
                </div>
                @if(templateSuggestions.length > 0 || !templateSuggestionLoading) {
                @for (item of templateSuggestions; track $index) {
                <div (click)="selectTemplate(item);fromScratch = false"
                    class="flex  border rounded-lg p-2 gap-2 items-center hover:bg-orange-100 cursor-pointer h-full min-h-[2rem] w-full min-w-[4rem]  "
                    [ngClass]="{'border-orange-500': selectedTemplate?._id === item._id , 'bg-orange-50' : selectedTemplate?._id === item._id}">
                    @if(item.mimeType == "pdf"){
                    <img src="images/file_types/pdf.png" alt="" width="40" height="40">
                    }@else if (item.mimeType == "docx") {
                    <img src="images/file_types/docx.png" alt="" width="40" height="40">
                    }@else {
                    <img src="images/file_types/invalid.png" alt="" width="40" height="40">
                    }
                    <div class="flex flex-col gap-1">
                        <span class="text-xs">{{item.name |truncate:20}}</span>
                        <div class="flex gap-1 items-center">
                            <img [src]="item.createdBy | userAvatar" alt="User Avatar" class="w-4 h-4 rounded-full" />
                            <span class="text-xs">{{item.createdBy | fullName}}</span>
                        </div>
                    </div>
                </div>
                }
                }
                @else {
                @for (item of [0,1,2]; track $index) {
                <div class="flex bg-gray-200 rounded-lg p-2 gap-2 items-center animate-pulse h-full min-h-[2rem]">
                </div>

                }
                }
            </div>
            <div class="flex  justify-end gap-2">
                <p-button styleClass="border px-3 py-2 rounded-xl w-100 text-black text-sm " label="Cancel"
                    (onClick)="templateSuggestionLoading=false"></p-button>
                <div
                    class="group theme-border rounded-xl shadow-md shadow-orange-100 transition-all duration-300 ease-in-out active:scale-95 active:transform ">
                    <p-button severity="primary" iconPos="left" (onClick)="callAIAssistant()"
                        [disabled]="!selectedTemplate && !fromScratch"
                        styleClass="w-100 bg-white  px-3 py-2 rounded-xl  text-sm  ">
                        <div class="flex gap-1 items-center ">
                            <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top" class=" h-5 w-5"
                                alt="Star icon">
                            <span class="theme-text">Generate</span>
                        </div>
                    </p-button>
                </div>

            </div>
        </div>
        }
        @else{
        <div class="flex flex-col  gap-4">
            @if(aiAssistant.loading){
            <div class="flex flex-col font-semibold items-center justify-center p-12">
                <img class="h-[12rem]  w-[10rem] animate-pulse " src="images/insights-image.png" alt="image">
                <span class="theme-text animate-pulse">{{aiAssistant.stage}}</span>
            </div>
            }
            @else {
            <p-dropdown [disabled]="loading" [options]="docTypes" [(ngModel)]="selectedDocType" optionLabel="name"
                placeholder="Select a Document Type" [loading]="docTypeLoading" styleClass="border" class="dropdown">
                <ng-template let-docType pTemplate="item">
                    <div class="flex flex-col">
                        <span class="font-bold">{{docType.name}}</span>
                        <span class="text-gray-500 doc-type-dropdown">{{docType.description}}</span>
                    </div>
                </ng-template>
            </p-dropdown>

            <textarea pInputTextarea rows="5" class="flex-auto rounded-md ai-text-area" id="aiTextArea"
                [disabled]="loading" (keydown.enter)="getRelatedTemplates()"
                placeholder="Ask AI Assistant to generate document" [(ngModel)]="content"
                (ngModelChange)="checkWordLimit()"></textarea>

            @if(wordCount > wordLimit) {
            <p class="text-xs text-right text-red-500">Maximum word limit is 500</p>
            }
            @else {
            <p class="text-xs text-right -mt-2">{{ wordCount }} / {{wordLimit}} words</p>
            }

            <div class="flex flex-row w-full my-2 gap-2 overflow-x-auto whitespace-nowrap no-scrollbar">
                @for(question of promptQuestions;track $index) {
                <div class="cursor-pointer theme-border rounded-xl text-xs text-center font-semibold px-2 py-1"
                    [class.pointer-events-none]="loading" (click)="sendPrompt(question)">
                    {{question}}
                </div>
                }
            </div>

            <div class="flex justify-end">
                <div
                    class="group theme-border rounded-xl shadow-md shadow-orange-100  transition-all duration-300 ease-in-out active:scale-95 active:transform ">
                    <p-button severity="primary" iconPos="left" (onClick)="getRelatedTemplates()"
                        [disabled]="!content.length || loading || !selectedDocType"
                        styleClass="w-100 bg-white  px-3 py-2 rounded-xl  text-sm  ">
                        <div class="flex gap-1 items-center ">
                            <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top" class=" h-5 w-5"
                                alt="Star icon">
                            <span class="theme-text">Send</span>
                        </div>
                    </p-button>
                </div>
            </div>
            }
        </div>
        @if (aiAssistant.loading) {
        <div class="flex justify-center items-center mb-2">
            <p-progressBar mode="indeterminate" color="#FF6A2A" [style]="{'height': '6px' , 'width':'25rem'}" />
        </div>
        }
        }
    </ng-template>
</p-dialog>