<div class="chat-bubble w-full">
    <div class="receiver w-full">
        @if (Chat.sender == senderType.AI) {
        <div class="flex flex-row">
            <div class="profile-icon sender-icon">
                <img src="images/doc_ai.png" alt="Sender Profile Icon" class="profile-icon w-8 h-8">
            </div>
            @if (!Chat.content) {
            <div class="flex flex-row">
                <div class="message-receiver flex flex-column gap-2 mt-3">
                    <span class="skeleton" style="width: 100%;"></span>
                    <span class="skeleton" style="width: 80%;"></span>
                    <span class="skeleton" style="width: 50%;"></span>
                </div>
            </div>
            }
            @if (Chat.content) {
            <div class="message-receiver flex flex-column gap-2">
                @if (Chat.contentType == contentTypeEnum.TEXT) {
                <span>
                    <app-text [text]="Chat.content"></app-text>
                    @if (Chat.isLoading) {
                    <span class="moving-icon"><span class="w-2 h-2 bg-orange-500 rounded-full"></span></span>
                    }
                </span>
                }
                <span class="time">
                    {{Chat.timestamp | dateFormatter}}
                </span>
            </div>
            }
        </div>
        }
    </div>
    @if (Chat.sender == senderType.USER) {
    <div class="sender">
        <div class="flex flex-row">
            <div class="message-sender flex flex-column gap-2">
                <span>{{Chat.content}}</span>
                <span class="time">
                    {{Chat.timestamp | dateFormatter}}
                </span>
            </div>
        </div>
    </div>
    }
</div>