
import { USER_TYPE } from "../../@core/models/enums";
import { Document, IAttachment, IShareUser, IOrganization, IUser, SHARE_ACCESS } from "../../@core/models/model"
import { CONTRACT_STATUS } from "../../@shared/components/badge/badge.enums"
import { CONTRACT_APPROVAL_STATUS } from "../../@shared/components/user-chips/user-chips.component";
import { IDocumentType } from "./settings.model";


export enum CREATE_CONTRACT_TYPES {
    EMPTY = 'EMPTY',
    UPLOAD = 'UPLOAD',
    GENERATE = 'GENERATE'
}

export enum CONTRACT_TYPE {
    ALL = 'ALL',
    DRAFTED = 'DRAFTED',
    SHARED = 'SHARED',
}

export enum CONTRACT_DOCUMENTS {
    ALL = 'All Documents',
    DRAFTED = 'Drafted Documents',
    SHARED = 'Shared Documents'
}

export enum IContractRole {
    OWNER = 'OWNER',
    CONTRACTOR = 'CONTRACTOR',
    NONE = 'NONE'
}

export interface IContract {
    readonly _id: string
    organization: string | IOrganization,
    attachment: string | IAttachment['_id'] | IAttachment,
    name: string,
    description: string,
    checkpoint: string,
    users: {
        user: string | IUser['_id'] | IUser,
        role: string
    }[],
    status: CONTRACT_STATUS,
    createdBy: string | IUser['_id'] | IUser,
    defaultSignature: SIGNATORY_TYPE,
    docusignId: string,
    adobeSignId: string,
    updatedAt: string,
    summary?: string,
    currentUserRole?: SHARE_ACCESS, // Not saved in model, this field comes on fetching contracts
    linkedDocType: IDocumentType
}

export interface IContractSection {
    title: string;
    content: string;
}

export interface IContractInsights extends Document {
    summary: string | IAttachment | IAttachment['_id'];
    customSummary?: string | IAttachment | IAttachment['_id'];
    documentInSync: boolean
    contract: IContract | string | IContract['_id'];
    createdBy: Partial<IUser> | string | IUser['_id'];
    createdAt: Date;
    updatedAt: Date;
}

export interface IContractApprovalConf extends Document {
    contract: string | IContract,
    approvers: IShareUser[],
    createdBy: IUser,
    status: CONTRACT_APPROVAL_STATUS,
}

export interface IContractSignatoryConf extends Document {
    contract: string | IContract['_id'] | IContract,
    signatories: {
        email: string,
        name: string,
        status: string,
        signedAttachment?: string,
        updatedAt: string,
        user: string | IUser,
        type: USER_TYPE
    }[],
    createdBy: IUser,
    status: string,
}

export enum SIGNATORY_TYPE {
    DOCU_SIGN = 'DOCU_SIGN',
    ADOBE_SIGN = 'ADOBE_SIGN',
}

