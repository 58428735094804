import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SpeedDialModule } from 'primeng/speeddial';
import { TooltipModule } from 'primeng/tooltip';
import { SOCKET_EVENTS, SocketService } from '../../../../../@core/services/socket.service';
import { TruncatePipe } from "../../../../../@shared/pipes/truncate.pipe";
import { CheckScreenSize } from '../../../../../services/check-screen-size.service';
import { ContractInsightsService } from '../../../../services/contract-insights.service';
import { ContractTagService, IGetContractTagMappings } from '../../../../services/contract-tag.service';
import { DataTypeComponent } from "./components/data-type/data-type.component";

@Component({
  selector: 'app-key-highlights',
  standalone: true,
  imports: [
    ChipModule,
    FormsModule,
    ButtonModule,
    AutoCompleteModule,
    TooltipModule,
    TruncatePipe,
    DataTypeComponent,
    SpeedDialModule,
    CommonModule,
    ProgressSpinnerModule
  ],
  templateUrl: './key-highlights.component.html',
  styleUrl: './key-highlights.component.scss'
})
export class KeyHighlightsComponent implements OnInit, OnDestroy {

  @Input() contractId: string = "";
  documentInSync: boolean = false;
  tagsInSync: boolean = false;

  contractTagMappings: (IGetContractTagMappings & { loading?: boolean })[] = []
  tagSuggestions: { name: string, _id: string }[] = []
  editTagTypeId: string = ''
  selectedTagId: string = ''
  changedValue: string = ''
  isEditing = false;
  loading = false;
  suggestEditItems =
    [{
      icon: 'pi pi-check',
      command: () => {

      }
    },
    {
      icon: 'pi pi-times',
      command: () => {

      }
    }]
  onEditTag: boolean[] = []
  extractEntitiesSubscription: any
  suggestionsLoading: boolean = false
  isSmallScreen: boolean = false

  constructor(private contractTagService: ContractTagService,
    private contractInsightsService: ContractInsightsService,
    private socketService: SocketService,
    private checkScreenSizeService: CheckScreenSize
  ) {
    this.extractEntitiesSubscription = this.socketService.on<{ mappings: IGetContractTagMappings[], documentInSync: boolean, singleElement: boolean }>(SOCKET_EVENTS.CONTRACT_TAG_MAPPING).subscribe(result => {
      this.updateMappings(result[0])
    });
  }
  ngOnInit(): void {
    this.getMappings()
    this.isSmallScreen = this.checkScreenSizeService.checkScreenSize()
  }

  ngOnDestroy(): void {
    if (this.extractEntitiesSubscription) {
      this.extractEntitiesSubscription.unsubscribe()
    }
  }

  getTagMappings(refresh = false, tagTypeId?: string) {
    if (refresh) this.documentInSync = false
    if (tagTypeId) {
      let mapping = this.contractTagMappings.find(item => item.tagTypeId == tagTypeId)
      if (mapping) {
        mapping.loading = true;//item will be replaced with updated data, so no need to make it false
        mapping.tags = []
      }
    }
    else
      this.loading = true
    if (!this.documentInSync || tagTypeId) {
      this.contractInsightsService.extractTags(this.contractId, refresh, tagTypeId).subscribe(res => {
        if (!this.documentInSync) this.getMappings()
      })
    }
    else
      this.getMappings()
  }

  getMappings() {
    this.contractTagService.getContractTagMappings(this.contractId).subscribe(res => {
      if (res.success && res.data) {
        this.documentInSync = res.data.documentInSync
        this.contractTagMappings = res.data.mappings
        this.updateInSync(this.contractTagMappings)
        this.onEditTag = new Array(res.data.mappings.length).fill(false)
        this.loading = false
      }
    })
  }

  updateInSync(contractTagMappings: (IGetContractTagMappings & { loading?: boolean })[] = []) {
    this.tagsInSync = contractTagMappings.every(item => item.mappingsInSync ?? false)
  }

  updateMappings(data: { mappings: IGetContractTagMappings[], documentInSync: boolean, singleElement: boolean }) {
    if (!data.singleElement) this.documentInSync = data.documentInSync
    const updatedMappingsMap = new Map(data.mappings.map(item => [item.tagTypeId, item]));
    this.contractTagMappings = this.contractTagMappings.map(item => ({ ...(updatedMappingsMap.get(item.tagTypeId) ?? item), ...((data.singleElement && data.documentInSync) && { loading: false }) })).filter(item => item !== undefined)
    this.updateInSync(this.contractTagMappings)
    this.onEditTag = new Array(data.mappings.length).fill(false)
  }

  deleteClicked(tagTypeId: string, tagId: string) {
    this.contractTagService.removeContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: tagId }).subscribe(res => {
      if (res.success) {
        this.getTagMappings()
      }
    })
  }


  editClicked(tagTypeId: string) {
    this.selectedTagId = ''
    this.editTagTypeId = this.editTagTypeId == tagTypeId ? '' : tagTypeId
    let i = this.contractTagMappings.findIndex(tag => tag.tagTypeId == tagTypeId)
    if (this.contractTagMappings[i].dataType == 'Custom') {
      return
    }
    this.contractTagMappings[i].tags?.forEach(tag => {
      if (tag.aiGenerated || tag.dataType != 'Custom') {
        this.editTagTypeId = ''
      }
    })
    this.onEditTag[i] = !this.onEditTag[i]
  }

  onOptionSelect(event: AutoCompleteSelectEvent) {
    this.selectedTagId = event.value._id
  }

  addTagMapping(tagTypeId: string) {
    this.contractTagService.addContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: this.selectedTagId }).subscribe(res => {
      if (res.success) {
        this.selectedTagId = ''
        this.editTagTypeId = ''
        this.getTagMappings()
      }
    })
  }

  searchTags(event: any, tagTypeId: string) {
    this.suggestionsLoading = true
    this.contractTagService.getTagsBasedOnSearchValue({ contract: this.contractId, tagType: tagTypeId, searchValue: event.query }).subscribe(res => {
      if (res.success && res.data) {
        this.tagSuggestions = res.data.map((tag: { _id: string; name: string }) => ({
          ...tag,
          name: this.trimLetters(tag.name, 35)
        }));
        this.suggestionsLoading = false
      }
    })
  }

  trimLetters(text: string, charLimit: number): string {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + '...';
    }
    return text;
  }


  onEdit() {
    this.isEditing = true;
  }

  cancelEdit() {
    this.isEditing = false;
  }

  saveEdit() {
    this.isEditing = false;
  }

  suggestEdit(tagTypeId: string, tagId: string, close: boolean) {
    if (close) {
      this.contractTagMappings = []
      this.editTagTypeId = ''
      this.changedValue = ''
      this.loading = true
      this.contractTagService.getContractTagMappings(this.contractId).subscribe(res => {
        if (res.success && res.data) {
          this.contractTagMappings = res.data.mappings
          this.updateInSync(this.contractTagMappings)
          this.onEditTag = new Array(res.data.mappings.length).fill(false)
          this.loading = false
        }
      })
      return
    }
    this.contractTagService.addAItagToContractTagMapping({ contract: this.contractId, tagType: tagTypeId, tag: tagId, tagName: this.changedValue }).subscribe(res => {
      if (res.success) {
        this.loading = true
        this.contractTagService.getContractTagMappings(this.contractId).subscribe(res => {
          if (res.success && res.data) {
            this.contractTagMappings = res.data.mappings
            this.updateInSync(this.contractTagMappings)
            this.onEditTag = new Array(res.data.mappings.length).fill(false)
            this.loading = false
          }
        })
      }
    })
  }


}
