import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment.dev";
import { BaseService } from "../../@core/services/base.service";
import { IDocumentType, ITag, ITagType } from "../models/settings.model";

@Injectable({ providedIn: 'root' })
export class DocumentTypeService extends BaseService {

    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/document-type';

    getDocTypes() {
        return this.post<IDocumentType[]>(this.apiUrl + '/doc-types')
    }

    getLinkedTagTypes(docTypeId: IDocumentType['_id']) {
        return this.get<ITagType[]>(this.apiUrl, { docTypeId })
    }

    addUpdateDocType(docType: Partial<IDocumentType>) {
        return this.put<IDocumentType>(this.apiUrl, docType)
    }

    deleteDocType(docTypeId: string) {
        return this.delete<IDocumentType | null>(this.apiUrl + '/' + docTypeId)
    }

}