import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment.dev';
import { BaseService } from "../../@core/services/base.service";
import { IContract } from "../models/contract-management.model";




@Injectable({ providedIn: 'root' })
export class ContractInsightsService extends BaseService {


    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }


    apiUrl = environment.apiUrl + '/contract-management';

    generateSummary(contractId: string) {
        return this.get<IContract>(this.apiUrl + '/generate-summary/' + contractId)
    }

    fetchSummary(summaryId: string) {
        return this.get<string>(this.apiUrl + '/fetch-summary/' + summaryId)
    }

    fetchLatestSummary(contractId: string) {
        return this.get<{ summary: string, needsNewSummary: boolean }>(this.apiUrl + '/fetch-latest-summary/' + contractId)
    }

    summaryList(contractId: string) {
        return this.get<IContract>(this.apiUrl + '/summary-list/' + contractId)
    }

    deleteSummary(contractId: string, summaryId: string) {
        return this.delete(this.apiUrl + '/delete-summary/' + summaryId)
    }

    generateCustomSummary(contractId: string) {
        return this.get<IContract>(this.apiUrl + '/generate-custom-summary/' + contractId)
    }

    generateClauses(contractId: string, generateClauses: boolean) {
        return this.get<IContract>(this.apiUrl + '/generate-clauses/' + contractId, { generateClauses })
    }

    generateEmbeddings(contractId: string) {
        return this.get(this.apiUrl + '/generate-embeddings/' + contractId)
    }

    documentInSync(contractId: string) {
        return this.get<boolean>(this.apiUrl + '/document-in-sync/' + contractId)
    }

    extractTags(contractId: string, refresh: boolean = false, tagTypeId?: string) {
        return this.post<IContract>(this.apiUrl + '/extract-all-tags', { contractId, refresh, tagTypeId })
    }

}
