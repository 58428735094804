import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContractInsightsService } from '../../services/contract-insights.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ContractEditorService } from '../../../@shared/components/contract-editor/contract-editor.service';
import { ToastService } from '../../../@core/services/toast.service';

enum GENERATION {
  SUMMARIZE = 'SUMMARIZE',
  CONTINUE = 'CONTINUE',
  TRANSLATE = 'TRANSLATE'
}

@Component({
  selector: 'app-translate',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule],
  templateUrl: './translate.component.html',
  styleUrl: './translate.component.scss'
})

export class TranslateComponent implements OnInit, OnDestroy {
  @Input() contractId: string = "";

  clauseResponse: any = null;
  subscription: Subscription = new Subscription();
  promptText: string = "";
  generatedText: string = "";
  currentGeneration !: GENERATION;
  GENERATION = GENERATION;
  aiAssistant: {
    response: string,
    loading: boolean
  } = { response: '', loading: false };
  constructor(private contractInsightsService: ContractInsightsService, private contractEditorService: ContractEditorService, private toast: ToastService) {
  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
    this.clauseResponse = null;
  }

  onSuggestionMenuOptionClick(key: GENERATION) {
    this.aiAssistant = {
      response: '',
      loading: false
    }
    let prompt = ''
    if (key == GENERATION.CONTINUE || key == GENERATION.SUMMARIZE) {
      prompt = `${key} this text`
    }
    else if (key == GENERATION.TRANSLATE) {
      prompt = `Take the following professional text and rewrite it in a way that is simple and easy to understand for a general audience`
    }
    let payload = {
      prompt,
      prefix: this.promptText ?? '',
      suffix: ''
    }
    this.currentGeneration = key;
    this.getAISuggestion(payload);
  }


  getAISuggestion(payload: { prefix?: string, prompt: string, suffix?: string, content?: string }) {
    this.aiAssistant.loading = true;
    this.contractEditorService.getAISuggestions(payload).subscribe({
      next: (res: any) => {
        const { status, content } = res;
        if (status.includes('[DONE]')) {
          this.aiAssistant.response = content.message;
          this.aiAssistant.loading = false;
        } else if (status.includes('[ERR]')) {
          this.aiAssistant.loading = false;
          console.log(status, 'ERR')
          this.toast.error('Something went wrong, please try again');
        } else {
          this.aiAssistant.response = content.message;
          this.aiAssistant.loading = true;
        }
      },
      error: (err) => {
        console.log(err, 'err')
        this.aiAssistant.loading = false;
        this.toast.error('Something went wrong, please try again');
      },
    });
  }

  copyResponseToClipboard() {
    navigator.clipboard.writeText(this.aiAssistant.response).then(() => {
      this.toast.success('Copied to clipboard');
    })
  }
}
