<div class="content" [class]="dragAreaClass">
  <div class="text-center">
    @if(showDesc) {
    @if(!isFileAdded || !displayText){
    <div class="d-flex flex-column align-items-center justify-content-center text-center">
      <i class="pi pi-cloud-upload upload-image"></i>
      <h4 class="title">Drag your files here</h4>
      <p class="drag-text" tabindex="0" (click)="file.click()" (keydown.enter)="file.click()"
        (keydown.space)="file.click()">
        or
        <span class="click-here-input">click here</span>
        to upload files from your device<br>
        @if(fileTypes.length){
        Please upload {{fileTypes}} files only (Max size {{maxFileSize/(1024 * 1024)}} MB)
        } @else{
        (Max size {{maxFileSize/(1024 * 1024)}} MB)
        }
      </p>
    </div>
    } @else{
    <div class="text-center">
      <h4 class="drag-text-uploaded">{{ fileName }}</h4>
      <p-button styleClass="bg-arena-orange p-2 px-4 m-3 text-white" (onClick)="removeUploadedFile()"
        [label]="validFilesLength > 1 ? 'Remove Files' : 'Remove File'" severity="help" />
    </div>
    }
    <input type="file" #file [accept]="fileTypes" [multiple]="type !== 'folder'"
      [attr.webkitdirectory]="type === 'folder' ? true : null" (change)="onFileChange($event)" style="display: none" />
    }
  </div>
</div>