import { HostListener, Injectable } from '@angular/core';
import { BaseService } from '../@core/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class CheckScreenSize extends BaseService {
    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        this.checkScreenSize();
    }

    public checkScreenSize(): boolean {
        return window.innerWidth <= 1440;
    }
}