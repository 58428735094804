<section class="aspect-auto h-screen overflow-hidden">
    <section class="h-full flex flex-col">
        <app-top-bar class="top-bar"></app-top-bar>
        <div class="flex-1 grid grid-cols-12 w-full h-screen ">
            <div class="col-span-9 ">
                <div class="grid grid-row-12">
                    <div class="flex items-center row-span-1 bg-white border border-b-2 ">
                        <div class="flex flex-row justify-between items-center">
                            <div class="flex mt-3 mb-3 ml-4 gap-2 ">
                                <app-back></app-back>
                                @if(currentVersion){
                                <p class="text-lg font-medium">Today, {{ currentVersion.updatedAt | date:
                                    'shortTime' }}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="bottomBarVisible ? 'h-[95%]' : 'h-[100%]'" class="h-screen">
                        @if(contract){
                        <app-only-office-doc-editor [contractId]="contract._id" [user]="currentUser!"
                            [configData]="config">
                        </app-only-office-doc-editor>
                        }
                    </div>
                </div>
            </div>
            <div class="col-span-3 bg-white h-full border border-l-2">
                <div class="flex flex-col h-full ">
                    <div class="flex align-items-center justify-between pl-4 pr-2 pt-6 flex-shrink-0">
                        <h3 class="font-medium">Version History</h3>
                        <hr>
                        <div class="flex gap-2 align-items-center">
                        </div>
                    </div>
                    @if (!versions || versions.length ==0) {
                    <div class="flex justify-center items-center h-full">
                        <span class="text-gray-400">No versions available</span>
                    </div>
                    }
                    <div class="max-w-full pt-8 p-4">
                        <div>
                            <div class="flex items-center justify-between text-black text-sm mb-2">
                                @if(currentVersion){
                                <span>{{currentVersion.updatedAt | date: 'mediumDate'}} at {{
                                    currentVersion.updatedAt | date: 'shortTime' }}</span>
                                }
                                <span>Current Version</span>
                            </div>
                            @if(versionLoading){
                            <div class="space-y-2 animate-pulse">
                                <div class="flex justify-between items-center bg-gray-200 p-2 rounded-xl mb-2">
                                    <div class="flex gap-2">
                                        <div class="flex flex-col">
                                            <div class="flex flex-col">
                                                <div class="h-4 bg-gray-400 w-24 rounded"></div>
                                                <div class="h-3 bg-gray-400 w-40 rounded mt-1"></div>
                                            </div>
                                            <div class="flex gap-2">
                                                <div class="w-6 h-6 rounded-full bg-gray-400"></div>
                                                <div class="h-4 bg-gray-400 w-32 rounded mt-1"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="w-3 h-3 bg-gray-500 rounded-full mr-2"></div>
                                </div>
                            </div>
                            }
                            @else {
                            <div class="space-y-2">
                                <div
                                    class="flex justify-between items-center bg-orange-200 p-2 rounded-xl mb-2 cursor-pointer">
                                    <div class="flex gap-2">
                                        <div class="flex flex-col">
                                            <div class="flex flex-col">
                                                <span>{{currentVersion.name}}</span>
                                                @if(currentVersion){
                                                <p class="text-gray-800 font-medium"> <span class="text-black">
                                                        {{ currentVersion.updatedAt | date: 'mediumDate' }} at
                                                        {{ currentVersion.updatedAt | date: 'shortTime' }}
                                                    </span></p>
                                                }
                                            </div>
                                            <div class="flex gap-2">
                                                <img [src]="currentVersion.createdBy | userAvatar" alt="User Avatar"
                                                    class="w-6 h-6 rounded-full" />
                                                <span class="text-black">{{currentVersion.createdBy | fullName}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <span class="w-3 h-3 bg-orange-500 rounded-full mr-2"></span>
                                </div>
                            </div>
                            <hr>
                            @for (item of filteredVersions; track $index) {
                            <div class="space-y-2 mt-2">
                                <div (click)="$event.preventDefault();showVersion(item)"
                                    class="flex justify-between bg-gray-100 items-center p-2 rounded-xl mb-2 cursor-pointer text-black transition-all ease-in-out hover:bg-white border"
                                    [ngClass]="{'bg-white border-orange': selectedVersion && selectedVersion._id === item._id, 'bg-gray-100': !(selectedVersion && selectedVersion._id === item._id)}">
                                    <div class="flex gap-2">
                                        <div class="flex flex-col">
                                            <div class="flex flex-col">
                                                <!-- <span>{{item.name}}</span> -->
                                                <p class="text-black font-medium"> <span class="text-gray-800 ">
                                                        {{ item.updatedAt | date: 'mediumDate' }} at
                                                        {{ item.updatedAt | date: 'shortTime' }}
                                                    </span></p>
                                            </div>
                                            <div class="flex gap-2">
                                                <img [src]="item.createdBy | userAvatar" alt="User Avatar"
                                                    class="w-6 h-6 rounded-full" />
                                                <span class="text-gray-500">{{item.createdBy | fullName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <i class="pi pi-ellipsis-v cursor-pointer"
                                            (click)="$event.preventDefault();menu.toggle($event)"></i>
                                        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
                                    </div>
                                </div>
                            </div>
                            }
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>