import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BaseService } from "../../@core/services/base.service";
import { environment } from "../../../environments/environment.dev";
import { IFile } from "../components/settings/repository/repository.types";



@Injectable({ providedIn: 'root' })
export class RepositoryService extends BaseService {

    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/repository';
    parentFolderId: string | null = null

    setParentFolderId(parentFolderId: string | null) {
        this.parentFolderId = parentFolderId;
    }

    getParentFolderId() {
        return this.parentFolderId;
    }

    getFiles() {
        return this.get<IFile[]>(this.apiUrl)
    }

    getParentFiles(parent: string) {
        return this.get<IFile[]>(this.apiUrl + '/' + parent)
    }

    getFilePath(fileId: string) {
        return this.get<{ _id: string, name: string }[]>(this.apiUrl + '/file-path/' + fileId)
    }

    getFolderDetails(folderId: string) {
        return this.get<(IFile & { childs: IFile[] })>(this.apiUrl + '/folder/' + folderId)
    }

    getReviewFiles() {
        return this.get<IFile[]>(this.apiUrl + '/review-files')
    }

    getReviewFileDetails(fileId: string) {
        return this.get<IFile>(this.apiUrl + '/review-file/' + fileId)
    }

    getFailedFiles() {
        return this.get<IFile[]>(this.apiUrl + '/failed-files')
    }

    retryFailedFiles() {
        return this.post<IFile[]>(this.apiUrl + '/retry-failed')
    }

    getExtractedTags(fileId: string, docTypeId: string) {
        return this.get<{
            entities: [],
            extracted_entities: {
                [key: string]: {
                    entity_name: string,
                    extracted_entity: string,
                    reason: string
                }
            },
            lastElement: boolean
        }>(this.apiUrl + '/extract-tags', { fileId, docTypeId })
    }

    getDocTypeFiles(docTypeId: string) {
        return this.get<IFile[]>(this.apiUrl + '/get-doc-type-files', { docTypeId })
    }

    uploadFiles(formData: FormData) {
        return this.post<any>(this.apiUrl + '/upload-file', formData)
    }

    mapFiles(files: string[], documentType: string) {
        return this.post<boolean>(this.apiUrl + '/map-files', { files, documentType })
    }

    getSelectedDocType(ids: string[]) {
        return this.get<string[]>(this.apiUrl + '/selected-doc-type', { ids: ids })
    }

    submitReviewFile(extractedTags: {
        tagTypeName: string,
        tagName: string,
        tagType: string,
        reason: string
    }[], docTypeId: String, fileId: string) {
        return this.post<boolean>(this.apiUrl + '/submit-review-file', { fileId, extractedTags, docTypeId })
    }

    deleteFile(fileIds: string | string[]) {
        return this.delete<IFile>(this.apiUrl + '/file/', { fileIds, parentFolderId: this.parentFolderId })
    }

    clearRepository() {
        return this.delete(this.apiUrl + '/repo')
    }

    createFolder(formData: any) {
        return this.post<any>(this.apiUrl + '/create-folder', formData)
    }

    getExtractionStatusCounts() {
        return this.get<{ [key: string]: number }>(this.apiUrl + '/get-status-count')
    }
}