import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { UserAvatarPipe } from "../../../../../../@shared/pipes/user-avatar-pipe";
import { DateFormatterPipe } from "../../../../../../@shared/pipes/dateFormater.pipe";
import { BadgeComponent } from "../../../../../../@shared/components/badge/badge.component";
import { AccordionModule } from 'primeng/accordion';
import { CompareService } from '../compare.service';
import { IContract } from '../../../../../models/contract-management.model';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FullNamePipe } from "../../../../../../@shared/pipes/fullName.pipe";
import { TruncatePipe } from "../../../../../../@shared/pipes/truncate.pipe";
import { CompareSelectionComponent } from '../compare-selection/compare-selection.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastService } from '../../../../../../@core/services/toast.service';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-compare-history',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    UserAvatarPipe,
    AccordionModule,
    ProgressSpinnerModule,
    FullNamePipe,
    TruncatePipe,
    DateFormatterPipe,
    InputGroupAddonModule,
    InputGroupModule
  ],
  templateUrl: './compare-history.component.html',
  styleUrl: './compare-history.component.scss'
})
export class CompareHistoryComponent {

  @Input() documentId!: IContract['_id'];
  @Input() navRequired: boolean = false;
  @Input() documentType: string = '';
  history: any = [];
  filteredHistory: any = [];
  loading: boolean = false;
  constructor(
    private compareService: CompareService,
    private router: Router, private dialog: MatDialog,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.getHistory();
  }
  getHistory() {
    this.loading = true;
    this.compareService.getHistory(this.documentId).subscribe((res) => {
      this.history = res.data;
      this.filteredHistory = this.history
      this.loading = false;
    });
  }


  openCompareDialog() {

    const _dialogRef = this.dialog.open(CompareSelectionComponent, {
      disableClose: true,
      width: '40vw',
      maxHeight: '80vh',
      data: {
        contractId: this.documentId,
        documentType: this.documentType
      }
    });
    _dialogRef.afterClosed().subscribe(doc => {
      if (doc) {
        this.router.navigate(['/compare'], {
          queryParams: {
            documentId: this.documentId,
            comparedDocumentId: doc.contract._id,
            documentType: this.documentType,
            comparedDocumentName: doc.contract.name,
            compareType: doc.compareType,
            compareHistory: false
          }
        })
      }
    })
  }

  openComparison(compareId: string) {
    const url = this.router.createUrlTree(['/compare'], { queryParams: { compareId: compareId, compareHistory: true } }).toString();
    window.open(url, '_blank');
  }

  deleteComparison(compareId: string) {
    this.compareService.deleteComparison(compareId).subscribe((res) => {
      if (res.success) {
        this.toast.success('Comparison deleted successfully');
        this.getHistory();
      }
    });
  }

  openDocument(id: string, docModel: string) {
    let url = '';
    switch (docModel) {
      case 'Contract':
        url = this.router.createUrlTree(['/edit-document'], { queryParams: { documentId: id } }).toString();
        window.open(url, '_blank');
        break;
      case 'File':
        url = this.router.createUrlTree(['/home/settings/repository'], { queryParams: { fileId: id } }).toString();
        window.open(url, '_blank');
        break;
      case 'Version':
        url = this.router.createUrlTree(['/versions'], { queryParams: { documentId: id } }).toString();
        window.open(url, '_blank');
        break;
    }
  }
  searchCompares(event: any) {
    if ((event.target as HTMLInputElement).value.toLowerCase() == "") {
      this.filteredHistory = this.history;
    }
    else {
      this.filteredHistory = this.history.filter((compare: any) => {
        return compare.name.toLowerCase().includes((event.target as HTMLInputElement).value.toLowerCase());
      });
    }
  }

}
