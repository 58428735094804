@switch (dataType) {
@case (TAG_DATA_TYPES.CUSTOM) {
<div class="relative inline-flex items-center  py-1 px-2 rounded-full bg-arena-orange-300 text-nowrap">
    <span class="text-xs" [pTooltip]="value" tooltipPosition="top">{{value |
        truncate:45}}</span>
    <i class="pi pi-times-circle cursor-pointer ml-2" (click)="deleteTag()"></i>
    <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top"
        class="absolute -top-1 -right-1 h-4 w-4 border border-arena-orange-300 rounded-full bg-white p-0.5 shadow"
        alt="Star icon">
</div>
}
@case (TAG_DATA_TYPES.NUMBER) {
<input pInputText type="number" [(ngModel)]="value" [disabled]="!onEdit" [pTooltip]="value" tooltipPosition="top"
    class="w-full bg-white flex-1 outline-none border rounded-lg p-2" (ngModelChange)="valueChanged()" />
}
@case (TAG_DATA_TYPES.DATE) {
<p-calendar [(ngModel)]="value" [disabled]="!onEdit" [showIcon]="true" inputId="icondisplay"
    (ngModelChange)="valueChanged()" styleClass="w-full bg-white border rounded-lg p-2 outline-none" variant="filled" />
}
@case (TAG_DATA_TYPES.TEXT) {
<input type="text" [disabled]="!onEdit" class="w-full bg-white rounded-lg border border-gray-300 p-2 text-sm"
    [value]="value" [pTooltip]="value" tooltipPosition="top">
}
@default {
<span class="text-xs" [pTooltip]="value" tooltipPosition="top">{{value |
    truncate:45}}</span>
}


}