<div class="flex flex-col h-[95%]">
    <div class="flex-grow">
        <textarea class="w-full h-full border border-gray-300 rounded-lg p-2 resize-none" pInputTextarea
            [(ngModel)]="promptText" placeholder="Write a prompt..."></textarea>
    </div>

    <div class="flex justify-center gap-x-6 my-4">
        <p-button
            [icon]="(aiAssistant.loading && this.currentGeneration == GENERATION.CONTINUE) ? 'pi pi-sparkles pi-spin' : 'pi pi-sparkles'"
            iconPos="right" label="Continue" class="px-2 py-1 rounded-lg bg-arena-orange-900 text-white"
            (onClick)="onSuggestionMenuOptionClick(GENERATION.CONTINUE)"
            [disabled]="aiAssistant.loading || promptText.length==0"></p-button>
        <p-button
            [icon]="(aiAssistant.loading && this.currentGeneration == GENERATION.SUMMARIZE) ? 'pi pi-sparkles pi-spin' : 'pi pi-sparkles'"
            iconPos="right" label="Summarize" class="px-2 py-1 rounded-lg bg-arena-orange-900 text-white"
            (onClick)="onSuggestionMenuOptionClick(GENERATION.SUMMARIZE)"
            [disabled]="aiAssistant.loading || promptText.length==0"></p-button>
        <p-button
            [icon]="(aiAssistant.loading && this.currentGeneration == GENERATION.TRANSLATE) ? 'pi pi-sparkles pi-spin' : 'pi pi-sparkles'"
            iconPos="right" label="Translate" class="px-2 py-1 rounded-lg bg-arena-orange-900 text-white"
            (onClick)="onSuggestionMenuOptionClick(GENERATION.TRANSLATE)"
            [disabled]="aiAssistant.loading || promptText.length==0"></p-button>
    </div>

    <div class="flex-grow">
        <div class="relative h-full">
            <textarea class="w-full h-full border border-gray-300 rounded-lg p-2 resize-none" pInputTextarea
                [(ngModel)]="aiAssistant.response" disabled="true"></textarea>

            <div class="absolute bottom-2 right-2 text-xs border border-gray-400 rounded-md px-2 py-1 bg-white hover:bg-gray-200 cursor-pointer"
                (click)="copyResponseToClipboard()">
                <i class="pi pi-clipboard text-xs"></i> Copy
            </div>
        </div>
    </div>
</div>