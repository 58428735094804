import { Injectable } from '@angular/core';
import { BaseService } from '../@core/services/base.service';
import { ICountryCodes } from '../@shared/components/phone-input/helpers/countries-helper';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService extends BaseService {
    public country: ICountryCodes = {code: 'US', name:'United States', phoneCode:'+1'};

    setCountry(country: ICountryCodes) {
        this.country = country;
    }

    getCountry(): ICountryCodes {
        return this.country;
    }

    fetchCountryCode = async (latitude: number, longitude: number) => {
        const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
        const data = await response.json();
        return data.countryCode;
    }
}