import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { ButtonModule } from 'primeng/button';
import { CLIENT_EVENTS, SocketService } from '../@core/services/socket.service';
import { IUser } from '../@core/models/model';
import { AuthenticationService } from '../@core/services/authentication.service';

@Component({
  selector: 'app-app-layout',
  standalone: true,
  imports: [RouterModule, RouterModule, ButtonModule, TopBarComponent],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss'
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  currentUser!: IUser | null | undefined;
  constructor(private router: Router, private authService: AuthenticationService, private socketService: SocketService) {
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
      this.emitSocketEvent(CLIENT_EVENTS.JOIN_ORG_ROOM)
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.emitSocketEvent(CLIENT_EVENTS.LEAVE_ORG_ROOM)
  }

  emitSocketEvent(event: CLIENT_EVENTS) {
    const socket = this.socketService.getSocket()
    socket.emit(event, this.currentUser?.organization as string ?? '')
  }

}
