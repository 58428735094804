import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IConfig , DocumentEditorModule, DocumentEditorComponent} from '@onlyoffice/document-editor-angular';
import { IUser } from '../../../@core/models/model';
import { environment } from '../../../../environments/environment.dev';
import { ONLY_OFFICE_EDITOR_TYPE } from '../../../@core/models/enums';


interface Position {
  paragraph: number;
  offset: number;
}

interface Range {
  startPos: Position;
  endPos: Position;
}


@Component({
  selector: 'app-only-office-doc-editor',
  standalone: true,
  imports: [DocumentEditorModule],
  templateUrl: './only-office-doc-editor.component.html',
  styleUrl: './only-office-doc-editor.component.scss'
})
export class OnlyOfficeDocEditorComponent implements OnInit ,OnDestroy {
 
  @ViewChild('docEditor') docEditorRef!: DocumentEditorComponent;
  @Input() contractId: string = '';
  @Input() user !: IUser;
  config!: IConfig;
  connector: any;
  @Input()
  set configData(config: IConfig) {
    this.config = config;
  }
  public onlyOfficeServer = environment.onlyOfficeServer;
  private docEditor: any;
  private docsAPI: any;
  
  ngOnInit(): void {
  
  }

  comments: any[] = [];
 
  onDocumentReady = () => {
    console.log("Document is loaded" );
    this.docsAPI = (window as any).DocsAPI;
    this.docEditor = (window as any).DocEditor;
    if(this.config.documentType == ONLY_OFFICE_EDITOR_TYPE.WORD) {
      this.connector = this.docEditor.instances.docxEditor.createConnector();
    }
  }

  onDocumentStateChange = (event: any) => {
    console.log("Document state changed", event);
  }

  onLoadComponentError = (errorCode: any, errorDescription: any) => {
    switch (errorCode) {
      case -1: // Unknown error loading component
        console.log(errorDescription)
        break
      case -2: // Error load DocsAPI from http://documentserver/
        console.log(errorDescription)
        break
      case -3: // DocsAPI is not defined
        console.log(errorDescription)
        break
    }
  }




  ngOnDestroy(): void {
    // if(this.connector) {
    //   this.connector.destroy();
    // }
  }

}
