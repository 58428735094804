import { Pipe, PipeTransform } from '@angular/core';
import { IConversation, IMessages } from '../../contract-management/components/chat/chat.types';

@Pipe({
    name: 'categorizeByDate',
    standalone: true
})
export class CategorizeByDatePipe implements PipeTransform {
    transform(history: IConversation[]): any {
        const now = new Date();
        const today: IConversation[] = [];
        const last7Days: IConversation[] = [];
        const last30Days: IConversation[] = [];
        const data: IConversation[][] = [];
        history.forEach(item => {
            const createdAt = new Date(item.createdAt);
            const diffInDays = Math.floor((now.getTime() - createdAt.getTime()) / (1000 * 60 * 60 * 24));

            if (diffInDays === 0) {
                today.push(item);
            } else if (diffInDays <= 7) {
                last7Days.push(item);
            } else if (diffInDays <= 30) {
                last30Days.push(item);
            }
        });
        data.push(today, last7Days, last30Days);

        return data;
    }
}
