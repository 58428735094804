import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from './@core/services/authentication.service';
import { GeolocationService } from './services/geolocation.service';
import { countryCodes, ICountryCodes } from './@shared/components/phone-input/helpers/countries-helper';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'arena-ai-frontend';
  constructor(
    private primengConfig: PrimeNGConfig,
    private authenticationService: AuthenticationService,
    private geolocationService: GeolocationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.primengConfig.zIndex = {
      modal: 1200,    // dialog, sidebar
      overlay: 1200,  // dropdown, overlaypanel
      menu: 1200,     // overlay menus
      tooltip: 1200   // tooltip
    };
    this.setCountryCodeFromGeolocation()
    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  getCountryByCountryCode(countryCode: string): { code: string, name: string, phoneCode: string } {
    const country = countryCodes.find(c => c.code === countryCode);
    return country ? country : { name: "United States", code: "US", phoneCode: "+1" };
  }

  setCountryCodeFromGeolocation() {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      const country = await this.geolocationService.fetchCountryCode(latitude, longitude);
      setTimeout(() => {
        const countryCode: ICountryCodes = this.getCountryByCountryCode(country)
        this.geolocationService.setCountry(countryCode);
      }, 3000)
    });
  }

  receiveMessage(event: MessageEvent) {

    // Verify the origin of the data for security
    if (event.origin !== 'http://localhost:4200') return;

    if (event.data.type === 'ARENA_CONTRACT_ANALYSER') {
      const data = event.data.data;
      const user = this.authenticationService.setUserData(data);
      if (user) {
        this.router.navigate(['/home'])
      } else {
        this.router.navigate(['/'])
      }
    }
  }

}
