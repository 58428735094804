import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, interval, switchMap } from 'rxjs';
import { BaseService } from '../../@core/services/base.service';
import { environment } from '../../../environments/environment.dev';
import { IMessages } from '../components/chat/chat.types';
import { ChatSource } from '../components/chat/chat.component';
@Injectable({
    providedIn: 'root'
})
export class AiChatService extends BaseService {
    private url = environment.apiUrl + '/ai-chat'
    constructor(
        protected override http: HttpClient,
        protected ngZone: NgZone
    ) {
        super(http, ngZone);
    }

    fetchStreamedResponse(prompt: string, contractId: string, selectedConversation: string, compareDocumentIds?: { _id: string, docModel: string, name: string }[], docModel?: string,) {
        const compareDocuments = JSON.stringify(compareDocumentIds)
        const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
        return this.stream<string>(this.url + '/conversation', { prompt, contractId, compareDocuments, docModel, conversation: selectedConversation, time_zone })
    }
    getResponse(prompt: string) {
        return this.get<string>(this.url, { prompt })
    }
    fecthSuggestions() {
        return this.get<string[]>(this.url + '/suggestions', {})
    }
    getResponseFromSuggestion(suggestion: string) {
        return this.get<string>(this.url + '/suggestion', { suggestion })
    }
    getHistory(userId: string) {
        return this.get<string[]>(this.url + '/history', { userId })

    }
    getAllConversations(document: string) {
        return this.get<string[]>(this.url + '/conversations', { document })
    }

    getConversation(conversation: string) {
        return this.get<IMessages[]>(this.url + '/conversation/' + conversation)
    }
    getPromptSuggestions(id: string, chatSource: ChatSource) {
        return this.post<string[]>(this.url + '/prompt-suggestions', { id, chatSource })
    }
    updateConversationName(conversation: string, name: string) {
        return this.put(this.url + '/conversation/' + conversation, { name })
    }
    deleteConversation(conversation: string) {
        return this.delete(this.url + '/conversation/' + conversation)
    }
}