import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkdownService } from 'ngx-markdown';
@Pipe({
    standalone: true,
    name: 'formatText',
})
export class FormatTextPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
        private markdownService: MarkdownService
    ) { }

    transform(value: string) {
        this.renderer();
        return value;
    }

    renderer() {
        this.markdownService.renderer.table = (header: string, body: string) => {
            return `
              <div class="overflow-x-auto text-xs">
                <table class="min-w-full table-auto border-collapse border border-gray-200 mb-4">
                  <thead class="bg-gray-100">
                    ${header.replace(/<th>/g, '<th class="px-4 py-2 border border-gray-300 text-left text-xs font-medium text-gray-700">')
                    .replace(/<\/th>/g, '</th>')}
                  </thead>
                  <tbody class="bg-white text-xs">
                    ${body.replace(/<td>/g, '<td class="px-4 py-2 border border-gray-300 text-xs text-gray-600">')
                    .replace(/<\/td>/g, '</td>')
                    .replace(/<tr>/g, '<tr class="hover:bg-gray-50">')
                    .replace(/<\/tr>/g, '</tr>')}
                  </tbody>
                </table>
              </div>
            `;
        };

        this.markdownService.renderer.text = (text: string) => {
            let formattedText = text;

            formattedText = formattedText.replace(/\n/g, '<br>');

            formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

            formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');

            // Jira ticket ID
            formattedText = formattedText.replace(
                /\b(ARN-\d+)\b/g,
                '<a href="https://inncircles.atlassian.net/browse/$1" target="_blank">$1</a>'
            );

            formattedText = formattedText.replace(
                /\[\s*([^\]]+?)\s*\]\(\s*(https?:\/\/[^\s]+?)\s*\)/g,
                (match, p1, p2) => `<a href="${p2.trim()}" target="_blank">${p1.trim()}</a>`
            );

            return formattedText.replace(
                /&lt;(\d+)&gt;(.*?)&lt;\/\1&gt;(?:\[Page (\d+)\])?/g,
                (match, group, content, pageNum) => {
                    const escapedContent = this.escapeHtml(content);
                    const sourceNum = group.trim();
                    return `
                    <span class="relative group">
                <span class="cursor-help text-xs rounded-full bg-orange-500 text-white px-2 py-1">${sourceNum}</span>
                <span class="absolute text-xs invisible opacity-0 group-hover:visible group-hover:opacity-100 z-10 bg-black text-white rounded-md p-2 w-52 transition-all duration-300 transform -translate-x-1/2 left-1/2 mt-2">
                   ${escapedContent}${pageNum ? ` [Page ${pageNum}]` : ''}
                  <span class="absolute w-0 h-0 border-t-8 border-t-black border-x-8 border-x-transparent top-[-8px] left-1/2 transform -translate-x-1/2"></span>
                </span>
              </span>
                  `;
                }
            );
        };


    }


    escapeHtml(unsafe: string): string {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;')
    }
}
